<template>
    <modal ref="modalAgregarEquipoModelo" titulo="Añadir equipo modelo" adicional="Añadir" @adicional="agregarEquipoMOdelo">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-9 my-2">
                <p class="input-label-top">Equipo modelo</p>
                <ValidationObserver ref="validator">
                    <ValidationProvider v-slot="{ errors }" name="equipo" rules="required">
                <el-select v-model="ModelTeam" placeholder="Seleccionar equipo modelo" size="small" class="w-100">
                    <el-option v-for="item in select_equipos_modelos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        :disabled="isDisabled.includes(item.id)"
                    />
                </el-select>
                <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </ValidationObserver>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data(){
        return {
            options: [],
            ModelTeam: null,
            id_ops:null
        }
    },
    computed: {
        ...mapGetters({
            select_equipos_modelos: 'selects/selects/select_equipos_modelos',
            equipos: 'obras/informacion/equipos'
        }),
        isDisabled(){
            return this.equipos.map(el=> el.id_producto);
        }
    },
    async created(){
        this.Action_get_selects_all_equipos_modelos()
    },
    methods: {
        ...mapActions({
            Action_get_selects_all_equipos_modelos: 'selects/selects/Action_get_selects_all_equipos_modelos',
            Action_add_opportunity_equipo: 'oportunidades/oportunidades/Action_add_opportunity_equipo',
            obtenerEquipos:'compras/ordenes_compra/Action_get_equipos',
            Action_get_informacion_equipos: 'obras/informacion/Action_get_informacion_equipos',
        }),
        toggle(data){
            this.cleanForm();
            this.id_ops = data
            this.$refs.modalAgregarEquipoModelo.toggle()
        },
        async agregarEquipoMOdelo(){
            const valid = await this.$refs.validator.validate();
            if (valid) {
                const params = {
                    id: this.id_ops,
                    // id: this.$route.params.id,
                    payload: { id_equipo_modelo: this.ModelTeam }
                }
                await this.Action_add_opportunity_equipo(params)
                this.$refs.modalAgregarEquipoModelo.toggle()
                await this.Action_get_informacion_equipos(this.$route.params.id)
            }
        },
        cleanForm(){
            this.$refs.validator.reset();
            this.ModelTeam = null,
            this.id_ops = null
        }
    }
}
</script>

<style lang="css" scoped>
</style>
